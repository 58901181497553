import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/";
import userRole from "@/helpers/userRoles.js";

//const DEFAULT_TITLE = process.env.VUE_APP_NAME;
Vue.use(VueRouter);

const DEFAULT_TITLE = "Anewo Orders";
const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Anewo Orders von datafieber.com",
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("../views/Home.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pickup",
    name: "pickup",
    component: () => import("../views/Pickup.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const user = store.getters["user/getUser"];
  //console.log("loggedin", store.getters["user/loggedIn"]);
  //console.log(to, from);
  if (store.getters["user/loggedIn"] && to.path === "/") {
    //console.log("loggedin");
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!user) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (to.matched.some((record) => record.meta.role)) {
        if (
          user.role === userRole.userRole.SuperUser ||
          user.role === to.meta.role
        ) {
          next();
        } else {
          next({ name: "login" });
        }
      } else {
        next();
      }
    }
  } else if (to.path === "/") {
    //console.log("loginseite");
    /*if (user) {
      next();
    } else {
      next();
    }*/
    next();
  } else {
    next();
  }
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
