import DataService from "@/services/DataService";
import moment from "moment";

export default {
  getAll() {
    return DataService.apiClient.get("/meal/items/");
  },
  getByDateRange(startdate, enddate) {
    return DataService.apiClient.post("/meal/itemsbydaterange/", {
      startdate,
      enddate,
    });
  },
  getByDate(date) {
    return DataService.apiClient.get(
      "/meal/itemsbydate/" + moment(date).format("YYYY-MM-DD")
    );
  },
  get(id) {
    return DataService.apiClient.get("/meal/item/" + id);
  },
  findByName(name) {
    return DataService.apiClient.get("/meal/findbyname/" + name);
  },

  save(item) {
    //console.log(item);
    if (item.id != null) {
      return DataService.apiClient.patch("/meal/edit/" + item.id, item.data);
    } else {
      return DataService.apiClient.post("/meal/add", item.data);
    }
  },
};
