import UserService from "@/services/UserService";
import enumRoles from "@/helpers/userRoles";
import browser from "browser-detect";

const state = {
  user: localStorage.getItem("order-user") || null,
  userlist: [],
  userRoles: enumRoles,
  isRefreshingToken: false,
  userLieferorte: [],
  pushSubscription: false,
};

const getters = {
  loggedIn(state) {
    return !!state.user;
  },
  getUser(state) {
    return state.user;
  },
  getUserList(state) {
    return state.userlist;
  },
  isSuperUser(state) {
    if (!state.user) return false;

    return !!(state.user.role === state.userRoles.userRole.SuperUser);
  },
  userRoles(state) {
    return state.userRoles;
  },
  isTokenRefreshing(state) {
    return state.isTokenRefreshing;
  },
  getUserLieferorte(state) {
    return state.userLieferorte;
  },
  getPushSubscription(state) {
    return state.pushSubscription;
  },
  getPushToken(state) {
    return state.user.getPushToken ? state.user.getPushToken : null;
  },
};

const actions = {
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      UserService.login(data)
        .then((response) => {
          commit("LOGIN", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshToken({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (!state.isRefreshingToken) {
        state.isRefreshingToken = true;

        if (!state && !state.user) {
          reject("not logged in");
        }
        UserService.refreshToken(state.user.refresh_token)
          .then((response) => {
            //console.log("Refresh", response.data.data);
            commit("REFRESH_TOKEN", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            state.isRefreshingToken = false;
          });
      }
    });
  },
  loadUserFromStore({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("LOAD_USER");
      if (state.user) {
        resolve(state.user);
      } else {
        reject(new Error("No user found"));
      }
    });
  },
  logout({ commit }) {
    commit("LOGOUT");
    UserService.logout().finally(() => {
      return Promise.resolve();
    });
  },
  clearStoredUser({ commit }) {
    commit("LOGOUT");
    return Promise.resolve();
  },
  fetchUserList({ commit }) {
    return new Promise((resolve, reject) => {
      UserService.fetchAll()
        .then((response) => {
          commit("SET_USER_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      UserService.delete(id)
        .then(() => {
          commit("REMOVE_USER", id);
          resolve(id);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteUsersByCustomer({ commit }, customerId) {
    return new Promise((resolve, reject) => {
      UserService.deleteUsersOfCustomer(customerId)
        .then((result) => {
          /* let removedList = [];
          if (result.data.data.removedList) {
            removedList = result.data.data.removedList;
            removedList.forEach((userid) => {
              commit("REMOVE_USER", userid);
            });
          }
          resolve(removedList);*/
          commit();
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  subscriptionCheck({ commit }, fcm) {
    return new Promise((resolve) => {
      if (!fcm) {
        commit("SET_PUSH_TOKEN", null);
        commit("SET_PUSH_SUBSCRIPTION", false);
        return resolve();
      }

      fcm
        .getToken(
          "BB55zgrbbRd-9rx0LFUcfNwiB5rPTxsoWutQF_JG0UcwOcrH49Zf5B6CYxYk8F_xWRmkm1vS-WTXS2jONrMcmHs"
        )
        .then((token) => {
          return UserService.subscriptionCheck(token);
        })
        .then((response) => {
          //  console.log(response);
          if (response.data.subscribed === true) {
            commit("SET_PUSH_TOKEN", response.data.token);
            commit("SET_PUSH_SUBSCRIPTION", response.data.subscribed);
          }
          return resolve(response.data);
        })
        .catch(() => {
          commit("SET_PUSH_TOKEN", null);
          commit("SET_PUSH_SUBSCRIPTION", false);
          return resolve();
        });
    });
  },
  subscribePush({ commit }, fcm) {
    return new Promise((resolve, reject) => {
      //get FCM Token

      if (!fcm) return resolve(null);

      fcm
        .getToken(
          "BB55zgrbbRd-9rx0LFUcfNwiB5rPTxsoWutQF_JG0UcwOcrH49Zf5B6CYxYk8F_xWRmkm1vS-WTXS2jONrMcmHs"
        )
        .then((token) => {
          //console.log(token);
          commit("SET_PUSH_TOKEN", token);
          const bresult = browser();
          return UserService.subscribePush({
            token,
            os: bresult.os,
            browser: `${bresult.name} version: ${bresult.version}`,
          });
        })
        .then(() => {
          commit("SET_PUSH_SUBSCRIPTION", true);
          return resolve();
        })

        .catch((error) => {
          commit("SET_PUSH_SUBSCRIPTION", false);
          //console.log(error);
          return reject(error);
        });
    });
  },
  unsubscribePush({ commit, state }, fcm) {
    return new Promise((resolve, reject) => {
      fcm
        .deleteToken()
        .then(() => {
          return UserService.unsubscribePush(state.user.pushToken || null);
        })
        .then(() => {
          commit("SET_PUSH_SUBSCRIPTION", false);
          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  LOGIN(state, data) {
    state.user = data.user;
    state.user.token = data.token;
    state.user.refresh_token = data.refresh_token;
    localStorage.setItem("order-user", JSON.stringify(state.user));
    UserService.setAuthToken(data.token);
  },
  REFRESH_TOKEN(state, data) {
    state.user.token = data.token;
    //state.user.refresh_token = data.refresh_token;
    localStorage.setItem("order-user", JSON.stringify(state.user));
    UserService.setAuthToken(data.token);
  },
  LOGOUT(state) {
    state.user = null;
    localStorage.removeItem("order-user");
    localStorage.removeItem("order");
    localStorage.removeItem("selectedDay");
    UserService.setAuthToken(null); //Reset auth token
  },
  SET_USER_LIST(state, data) {
    state.userlist = data;
  },
  SET_USER_LIEFERORTE(state, value) {
    state.userLieferorte = value;
  },
  SET_PUSH_SUBSCRIPTION(state, value) {
    state.pushSubscription = value;
  },
  SET_PUSH_TOKEN(state, token) {
    state.user.pushToken = token;
  },
  LOAD_USER(state) {
    const user = localStorage.getItem("order-user") || null;
    if (user != null) {
      state.user = JSON.parse(user);
      UserService.setAuthToken(user.token);
    }
  },
  REMOVE_USER(state, id) {
    if (state.userlist) {
      state.userlist = state.userlist.filter((item) => item._id !== id);
    }
  },
  SET_USER_DEPARTMENT(state, department = "default") {
    state.user.department = department;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
