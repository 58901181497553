import OrderService from "@/services/OrderService";
import moment from "moment";
import DaySort from "@/helpers/orderDaySorted";

const state = {
  orderDates: {},
  orderZeitraum: [],
  orderDays: [],
  orderEnd: null,
  selectedDay: {
    day: 1,
    date: null,
  },
  order: {
    customer: {},
    user: {},
    orderDate: null,
    days: [],
    status: "open",
    orderMode: "single",
  },
  grossgebindeOrder: false,
  grossBestellungOrder: false,
  orderMode: "single",
  orders: [],
  currentOrder: {},
  actualOrder: [],
  showDaySelector: false,
  showOrderDetails: false,
  showOrderOverviewDetails: false,
  showLieferorteSelector: false,
  showOrderPickupResultDialog: false,
  selectedLieferort: "all",
  resteOrder: null,
};

const getters = {
  getCurrentOrder(state) {
    return state.currentOrder;
  },
  getActualOrder(state) {
    return state.actualOrder;
  },
  getOrderZeitraum(state) {
    return state.orderZeitraum;
  },
  getOrderDays(state) {
    return state.orderDays;
  },
  getOrder(state) {
    return state.order;
  },
  getOrders(state) {
    return state.orders;
  },
  getResteOrder(state) {
    return state.resteOrder;
  },
  getSelectedDayOrders(state) {
    const dayOrders = state.order.days.filter(
      (item) => item.day == state.selectedDay.format("DD.MM.YYYY").toString()
    );
    return dayOrders;
  },
  getSelectedDay(state) {
    return state.selectedDay;
  },
  getBestellEnde(state) {
    return state.orderEnd;
  },
  isGrossgebindeOrder(state) {
    return state.grossgebindeOrder;
  },
  isGrossBestellungOrder(state) {
    return state.grossBestellungOrder;
  },
  getOrderMode(state) {
    return state.orderMode;
  },

  isBestellEnde(state) {
    if (state.orderEnd) {
      const current = moment();
      if (current.isBefore(state.orderEnd)) {
        return false;
      }
    }
    return true;
  },
  isDaySelectorShown(state) {
    return state.showDaySelector;
  },
  isOrderDetailsShown(state) {
    return state.showOrderDetails;
  },
  isOrderOverviewDetailsShown(state) {
    return state.showOrderOverviewDetails;
  },
  isLieferortSelectorShown(state) {
    return state.showLieferorteSelector;
  },
  isOrderPickupResultDialogShown(state) {
    return state.showOrderPickupResultDialog;
  },
  daySortedOrder(state) {
    let sorted = DaySort.sortOrderDays(state.order);
    return sorted;
  },
  getSelectedLieferort(state) {
    return state.selectedLieferort;
  },
};

const actions = {
  fetchCurrentOrder({ commit }, userId) {
    return new Promise((resolve, reject) => {
      OrderService.fetchCurrent(userId)
        .then((response) => {
          commit("SET_CURRENT_ORDER", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchActualOrder({ commit }, userId) {
    return new Promise((resolve, reject) => {
      OrderService.fetchActual(userId)
        .then((response) => {
          commit("SET_ACTUAL_ORDER", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchOrder({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      OrderService.fetch(orderId)
        .then((response) => {
          commit("SET_ORDER", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchOrders({ commit }, userId) {
    return new Promise((resolve, reject) => {
      OrderService.fetchAll(userId)
        .then((response) => {
          commit("SET_ORDERS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveOrder({ commit, state }) {
    return new Promise((resolve, reject) => {
      OrderService.saveOrder(state.order)
        .then((response) => {
          commit("SAVE_ORDER", response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getNextUnpickedOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      OrderService.getNextUnpickedOrder(
        data.meal._id,
        data.meal.gerichtnr,
        data.amount
      )
        .then((result) => {
          commit("SET_RESTE_PICKUP", result.data.data);
          return resolve(result.data.data);
        })
        .catch((error) => {
          console.log(error);
          return reject(error);
        });
    });
  },

  updateUnpickedMeal({ commit }, value) {
    return new Promise((resolve, reject) => {
      OrderService.updateUnpickedMeal(
        value.meal._id,
        value.meal.gerichtnr,
        value.amount
      )
        .then((result) => {
          commit("UPDATE_UNPICKED_MEAL", result);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  stornoOrder({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("STORNO_ORDER");

      OrderService.saveOrder(state.order)
        .then((response) => {
          commit("SAVE_ORDER", response.data.data);
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchOrderDates({ commit }, orderEnd) {
    return new Promise((resolve, reject) => {
      OrderService.fetchDates()
        .then((response) => {
          let currentDate = moment(response.data.data.currentDate);
          let zeitraum = [];
          commit("SET_ORDER_DATES", response.data.data);

          //Simulate other day
          // currentDate = moment("2021-06-05");
          if (currentDate < orderEnd) {
            zeitraum[0] = moment(currentDate)
              .isoWeek(currentDate.isoWeek() + 1)
              .isoWeekday(1);
            zeitraum[1] = moment(currentDate)
              .isoWeek(currentDate.isoWeek() + 1)
              .isoWeekday(7);
          } else {
            orderEnd.isoWeek(orderEnd.isoWeek() + 1);

            zeitraum[0] = moment(currentDate)
              .isoWeek(currentDate.isoWeek() + 2)
              .isoWeekday(1);
            zeitraum[1] = moment(currentDate)
              .isoWeek(currentDate.isoWeek() + 2)
              .isoWeekday(7);
          }
          commit("SET_ORDER_END", orderEnd);
          commit("SET_ORDER_ZEITRAUM", zeitraum);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  initOrder({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      OrderService.fetchUserLast(data.user._id, state.orderMode)
        .then((response) => {
          if (response.data.data) {
            response.data.customer = data.customer;

            commit("SET_ORDER", response.data.data);
          } else {
            commit("INIT_ORDER", data);
          }

          let orderDays = [];
          for (let i = 0; i < data.customer.arbeitstage.length; i++) {
            let mWeekDay = moment().isoWeek(state.orderZeitraum[0].isoWeek());

            //prüfe jahreswechsel, wenn die aktuelle Woche die letzte im Jahr ist und die Startbestellwoche die erste
            //setze das Jahr auf das nächste
            if (state.orderZeitraum[0].isoWeek() < moment().isoWeek()) {
              mWeekDay.year(moment().year() + 1);
            }
            mWeekDay.isoWeekday(data.customer.arbeitstage[i]);
            orderDays.push(mWeekDay);
          }
          commit("SET_ORDER_DAYS", orderDays);

          let selectedDay = state.orderZeitraum[0];
          if (localStorage.getItem("selectedOrderDay")) {
            let storedDay = moment(
              JSON.parse(localStorage.getItem("selectedOrderDay"))
            );
            //check if storedDate is between Zeitraum
            if (
              moment(storedDay).isBetween(
                state.orderZeitraum[0],
                state.orderZeitraum[1]
              )
            ) {
              selectedDay = storedDay;
            }
          }
          commit("SET_SELECTED_DAY", selectedDay);
          return resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  INIT_ORDER(state, data) {
    state.order._id = null;
    state.order.user = data.user;
    //TODO Check for removing
    state.order.customer = data.customer;
    state.order.days = [];
    state.order.status = "open";
    state.order.orderMode = state.orderMode;
  },
  SET_ORDER_DATES(state, dates) {
    state.orderDates = dates;
  },
  SET_ORDER_ZEITRAUM(state, zeitraum) {
    state.orderZeitraum = zeitraum;
  },

  SET_ORDER_END(state, endDate) {
    state.orderEnd = endDate;
    state.order.bestellEnde = endDate.toString();
  },
  SET_ORDER_DAYS(state, days) {
    state.orderDays = days;
  },
  SET_ORDER(state, data) {
    state.order = data;
  },
  SET_CURRENT_ORDER(state, data) {
    state.currentOrder = data;
  },
  SET_ACTUAL_ORDER(state, data) {
    state.actualOrder = data;
  },
  SET_ORDERS(state, data) {
    state.orders = data;
  },
  SET_GROSSGEBINDE_ORDER(state, value) {
    state.grossgebindeOrder = value;
  },
  SET_GROSSBESTELLUNG_ORDER(state, value) {
    state.grossBestellungOrder = value;
  },
  SET_ORDER_MODE(state, value) {
    state.orderMode = value;
  },
  SET_SELECTED_DAY(state, day) {
    state.selectedDay = day;
    localStorage.setItem("selectedOrderDay", JSON.stringify(state.selectedDay));
  },
  UPDATE_UNPICKED_MEAL(state, value) {
    console.log(state, value);
  },
  SET_SELECTED_LIEFERORT(state, value) {
    state.selectedLieferort = value;
  },
  SET_RESTE_PICKUP(state, value) {
    state.resteOrder = value;
  },
  SAVE_ORDER(state, order) {
    if (order._id) {
      state.order._id = order._id;
      state.order.modifiedTS = order.modifiedTS;
      state.order.qrCode = order.qrCode;
    }
  },
  STORNO_ORDER(state) {
    if (state.order) {
      state.order.orderDate = new Date();
      state.order.status = "canceled";
      state.order.days = [];
    }
  },
  TOGGLE_DAY_SELECTOR(state, value) {
    state.showDaySelector = !!value;
  },

  TOGGLE_ORDER_DETAILS(state, value) {
    state.showOrderDetails = !!value;
  },
  TOGGLE_ORDER_OVERVIEW_DETAILS(state, value) {
    state.showOrderOverviewDetails = !!value;
  },
  TOGGLE_LIEFERORTE_SELECTOR(state, value) {
    state.showLieferorteSelector = value;
  },
  TOGGLE_ORDER_PICKUP_RESULT(state, value) {
    state.showOrderPickupResultDialog = value;
  },

  SET_MEAL_AMOUNT(state, data) {
    let orderItem = state.order.days.find(
      (item) =>
        item.day == state.selectedDay.format("DD.MM.YYYY") &&
        item.meal._id == data.item._id &&
        item.timeSlot == data.index &&
        (!item.lieferort || item.lieferort == data.lieferort)
    );

    if (orderItem) {
      orderItem.amount = data.amount;
      //If amount is 0 then remove it from oder.days list
      if (data.amount == 0) {
        let index = state.order.days.findIndex(
          (item) =>
            item.day == state.selectedDay.format("DD.MM.YYYY") &&
            item.meal._id == data.item._id &&
            item.timeSlot == data.index &&
            item.lieferort == data.lieferort
        );

        if (index >= 0) {
          state.order.days.splice(index, 1);
        } else {
          //set amount of found item
          orderItem.amount = data.amount;
        }
      }
    } else {
      state.order.days.push({
        amount: data.amount,
        meal: data.item,
        timeSlot: data.index,
        lieferort: data.lieferort,
        day: state.selectedDay.format("DD.MM.YYYY"),
        dayDate: state.selectedDay.toString,
      });
    }

    localStorage.setItem("order", JSON.stringify(state.order));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
