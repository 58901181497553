// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from "firebase/messaging";
import store from "./store";

class FirebasePush {
  _instance = null;
  firebaseConfig = null;
  messaging = null;
  serviceWorkerRegistration = null;
  onMessageCallback = null;

  constructor(config, swRegistration) {
    if (!this._instance) {
      this._instance = this;
    }
    this.serviceWorkerRegistration = swRegistration;
    this.firebaseConfig = config;
    let app = initializeApp(this.firebaseConfig);
    this.messaging = getMessaging(app);

    return this._instance;
  }
  setOnMessageCallback(cb) {
    this.onMessageCallback = cb;
    return this.onMessageCallback;
  }

  getToken(vapidKey) {
    return getToken(this.messaging, {
      serviceWorkerRegistration: this.serviceWorkerRegistration,
      vapidKey,
    })
      .then((token) => {
        onMessage(this.messaging, (payload) => {
          store.state.infoSnackbar = {
            show: true,
            title: payload.data.title,
            body: payload.data.body,
            timeout: 5000,
          };
        });
        return token;
      })
      .catch((error) => {
        return error;
      });
  }

  deleteToken() {
    return deleteToken(this.messaging);
  }
}

export default FirebasePush;
