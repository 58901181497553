<template>
  <v-app-bar
    app
    dark
    color="primary"
    v-if="loggedIn"
    :height="viewPane == 1 && orderPane == 0 ? '150px' : '56px'"
    class="pa-0 ma-0"
    id="appbar"
  >
    <v-container fluid class="ma-0 pa-0">
      <v-row
        fluid
        class="pl-2 pr-2"
        style="height: 48px; margin: 0px !important"
        align="center"
      >
        <v-img
          contain
          class="headerLogo"
          v-show="viewPane == 0"
          :src="logoUrl"
          max-width="80px"
          max-height="45px"
          alt="Logo"
        />

        <v-btn v-show="viewPane != 0" @click="viewPane = 0" icon
          ><v-icon class="ma-2 icon-home">mdi-home</v-icon>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          :loading="loadingPush"
          @click="toggleSubscription()"
          v-show="canNotify && viewPane != 1"
          fab
          small
          :color="pushSubscription ? 'success lighten-2' : 'warning lighten-2'"
          ><v-icon v-if="!pushSubscription" color="primary"
            >mdi-bell-off-outline</v-icon
          >
          <v-icon v-else color="primary">mdi-bell-check-outline</v-icon>
        </v-btn>

        <v-spacer v-show="viewPane == 0"></v-spacer>
        <v-btn
          class="ma-2 button-order"
          v-show="viewPane == 1 && orderPane == 0"
          @click="orderPane = 1"
          color="success"
          >Bestellung ansehen
        </v-btn>
        <v-btn
          class="ma-2 button-back"
          v-show="viewPane == 1 && orderPane == 1"
          @click="orderPane = 0"
          color="secondary"
          >Zurück zu den Essen
        </v-btn>
        <v-spacer v-show="viewPane != 0"></v-spacer>
        <v-btn v-show="viewPane == 0" icon @click="logout"
          ><v-icon class="icon-logout">mdi-logout</v-icon></v-btn
        >
        <v-btn
          class="button-basket mr-2"
          icon
          @click="orderPane = 1"
          v-show="viewPane == 1 && getOrderCount"
        >
          <v-badge
            class="badge-basket"
            overlap
            :content="getOrderCount"
            color="green"
          >
            <v-icon color="white icon-basket">mdi-basket</v-icon>
          </v-badge>
        </v-btn>
      </v-row>
      <v-row  fluid
        v-show="viewPane == 1 && orderPane == 0"
        class="ma-0 pa-0"
        style="margin: 0 !important; width: ">
         <SearchFilter />
      </v-row>
      <v-row
        fluid
        v-show="viewPane == 1 && orderPane == 0"
        class="ma-0 pa-0"
        style="margin: 0 !important; width: "
      >
     
        <DaySelector class="" ref="daySelector" />
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import DaySelector from "@/components/order/DaySelector";
import SearchFilter from "@/components/order/SearchFilter";
export default {
  name: "Header",
  data: () => ({
    loadingPush: false,
  }),
  components: { DaySelector, SearchFilter },
  methods: {
    //check if Push Notifications work kin browser
    canNotify() {
      return "Notification" in window;
    },
    showFilter() {},
    logout() {
      localStorage.removeItem("order-user");
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push("/");
        this.$notify({
          group: "dataline",
          type: "info",
          title: "Logout",
          text: "Sie sind jetzt abgemeldet.",
        });
      });
    },

    toggleSubscription() {
      //this.pushSubscription = !this.pushSubscription;
      this.loadingPush = true;
      if (!this.pushSubscription) {
        this.$store
          .dispatch("user/subscribePush", this.$store.getters["getFCMInstance"])
          .then(() => {
            this.$store.commit("SET_INFO_SNACKBAR", {
              show: true,
              title: "Push Benachrichtigung aktiviert",
              body: "Du erhältst jetzt aktuelle Benachrichtigungen",
            });
          })
          .finally(() => {
            this.loadingPush = false;
          });
      } else {
        this.$store
          .dispatch(
            "user/unsubscribePush",
            this.$store.getters["getFCMInstance"]
          )
          .then(() => {
            this.$store.commit("SET_INFO_SNACKBAR", {
              show: true,
              title: "Push Benachrichtigung deaktiviert",
              body: "Du erhältst jetzt keine Benachrichtigungen mehr.",
              bgColor: "secondary",
            });
          })
          .finally(() => {
            this.loadingPush = false;
          });
      }
    },
  },
  computed: {
    pushSubscription: {
      get() {
        return this.$store.getters["user/getPushSubscription"];
      },
      set(value) {
        this.$store.commit("user/SET_PUSH_SUBSCRIPTION", value);
      },
    },
    getOrderCount() {
      let orders = this.Order.days;
      let amount = 0;
      if (orders) {
        for (let index in orders) {
          amount += Number(orders[index].amount);
        }
        return amount < 100 ? amount : "99+";
      }
      return false;
    },
    logoUrl() {
      return `${process.env.VUE_APP_API_URL}/ressources/images/logo.png`;
    },
    loggedIn() {
      return this.$store.getters["user/loggedIn"];
    },
    Order() {
      return this.$store.getters["order/getOrder"];
    },
    viewPane: {
      get() {
        return this.$store.getters["getViewPane"];
      },
      set(id) {
        this.$store.commit("SET_VIEW_PANE", id);
      },
    },
    orderPane: {
      get() {
        return this.$store.getters["getOrderPane"];
      },
      set(id) {
        this.$store.commit("SET_ORDER_PANE", id);
      },
    },
    showDaySelector: {
      get() {
        return this.$store.getters["order/isDaySelectorShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_DAY_SELECTOR", value);
      },
    },

    isFilterIcon() {
      return this.$store.getters["meal/isFilterIcon"];
    },
  },
  updated() {
    if (this.orderPane == 0 && this.viewPane == 1) {
      // this.$refs.daySelector.dlgSelect = true;
    }
  },
};
</script>

<style scoped>
#logo {
  display: inline-block;
  min-width: 100px;
  max-height: 14px;
  width: 150px;
}

.headlerLogo {
  max-width: 40px;
  max-height: 40px;
}

.fs-red {
  background-color: #c3533e;
}
.version {
  font-size: 10px;
}
</style>
