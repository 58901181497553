import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import customer from "./modules/customer";
import meal from "./modules/meal";
import order from "./modules/order";
import { version } from "../../package.json";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    packageVersion: version || "0",
    appLoading: false,
    viewPane: 0,
    orderPane: 0,
    pickupUpdateInverval: null,
    resteMealUpdateInterval: null,
    runUpdateResteMealList: false,
    fcmInstance: null,
    notification: {
      show: false,
      title: "",
      body: "",
      icon: "",
    },
    infoSnackbar: {
      show: false,
      title: "",
      body: "",
      icon: "",
      bgColor: "secondary",
      textColor: "white",
      timeout: 3000,
    },
  },
  mutations: {
    SET_APP_LOADING(state, value) {
      state.appLoading = !!value;
    },
    SET_VIEW_PANE(state, pane) {
      state.viewPane = pane;
      if (state.viewPane == 0) {
        state.orderPane = 0;
      }

      if (state.viewPane != 2 && state.pickupUpdateInverval) {
        clearInterval(state.pickupUpdateInverval);
      }

      if (state.viewPane != 4 && state.resteMealUpdateInterval) {
        state.runUpdateResteMealList = false;
        clearInterval(state.resteMealUpdateInterval);
      }
    },
    SET_ORDER_PANE(state, pane) {
      state.orderPane = pane;
    },
    SET_PICKUP_UPDATE_INTERVAL(state, value) {
      state.pickupUpdateInverval = value;
    },

    SET_RESTEMEAL_UPDATE_INTERVAL(state, value) {
      state.resteMealUpdateInterval = value;
    },
    TOGGLE_UPDATE_MEAL_LIST_RUNNING(state, value) {
      state.runUpdateResteMealList = value;
    },
    SHOW_NOTIFICATION(state, value) {
      state.notification = value;
    },
    SET_INFO_SNACKBAR(state, value) {
      state.infoSnackbar.show = value.show || false;
      state.infoSnackbar.title = value.title || "";
      state.infoSnackbar.body = value.body || "";
      state.infoSnackbar.icon = value.icon || "";
      state.infoSnackbar.bgColor = value.bgColor || "secondary";
      state.infoSnackbar.textColor = value.textColor || "white";
      state.infoSnackbar.timeout = value.timeout || 3000;
    },
  },
  actions: {
    isCurrentDate(lastTimestamp) {
      let currentDate = new Date();
      let lastDate = new Date(lastTimestamp);

      return (
        currentDate.getFullYear() === lastDate.getFullYear() &&
        currentDate.getDate() === lastDate.getDate() &&
        currentDate.getMonth() === lastDate.getMonth()
      );
    },
  },
  getters: {
    appVersion(state) {
      return state.packageVersion;
    },
    appLoading(state) {
      return !!state.appLoading;
    },
    getViewPane(state) {
      return state.viewPane;
    },
    getOrderPane(state) {
      return state.orderPane;
    },
    getPickupUpdateInterval(state) {
      return state.pickupUpdateInverval;
    },
    getResteMealUpdateInterval(state) {
      return state.resteMealUpdateInverval;
    },
    isUpdateMealListRunning(state) {
      return state.runUpdateResteMealList;
    },
    getFCMInstance(state) {
      return state.fcmInstance;
    },
    getNotification(state) {
      return state.notification;
    },
    getInfoSnackbar(state) {
      return state.infoSnackbar;
    },
  },
  modules: {
    user,
    customer,
    meal,
    order,
  },
});
