import Vuetify from "../../plugins/vuetify";
import CustomerService from "@/services/CustomerService";

const state = {
  customer: null,
  branding: null,
};

const getters = {
  getCustomer(state) {
    if (state.customer && !state.customer.restekarte) {
      state.customer.restekarte = false;
    }
    return state.customer;
  },
  getBranding(state) {
    return state.branding;
  },
};

const actions = {
  fetchCustomer({ commit }, customerid) {
    return new Promise((resolve, reject) => {
      CustomerService.fetchCustomer(customerid)
        .then((response) => {
          commit("SET_CUSTOMER", response.data.data);
          if (response.data.data.branding) {
            commit("SET_BRANDING", response.data.data.branding);
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchBranding({ commit }) {
    return new Promise((resolve, reject) => {
      CustomerService.fetchBranding()
        .then((response) => {
          let branding = response.data.data.branding || null;
          commit("SET_BRANDING", branding);
          resolve(branding);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_CUSTOMER(state, data) {
    state.customer = data;

    if (!state.customer.restekarte) {
      state.customer.restekarte = false;
    }

    for (let department of state.customer.essenskosten) {
      if (!department.showOnlyGrossgebindeMeals) {
        department.showOnlyGrossgebindeMeals = false;
      }
      if (!department.showRestekarte) {
        department.showRestekarte = "not";
      }
    }
    // console.log(state.customer);
  },
  SET_BRANDING(state, data) {
    if (data.theme && data.active) {
      state.branding = data;
      Vuetify.framework.theme.themes.light = data.theme;
      Vuetify.framework.theme.themes.dark = data.theme;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
