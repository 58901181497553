/* eslint-disable no-console */

import { register } from "register-service-worker";
import FirebasePush from "./fcm";
import store from "./store";

if (
  process.env.NODE_ENV === "production" ||
  process.env.NODE_ENV === "development"
) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(registration) {
      console.log("Service worker has been registered.");
      setInterval(() => {
        registration.update();
      }, 1000 * 60 * 1); // e.g. hourly checks*/
      //firebaseMessaging.init(registration);
      const firebaseConfig = {
        apiKey: "AIzaSyBrj9ilM3LC6qdGPVSXvhXMAHOdgTA3JXk",
        authDomain: "freshstaff-1aa83.firebaseapp.com",
        projectId: "freshstaff-1aa83",
        storageBucket: "freshstaff-1aa83.appspot.com",
        messagingSenderId: "699152991162",
        appId: "1:699152991162:web:6483e134a159569b161383",
        measurementId: "G-DSHWQ4DJPS",
      };

      //Erstelle neues FirebasePush Objekt mit der Config und dem aktuellen ServiceWorker
      const fcm = new FirebasePush(firebaseConfig, registration);
      store.state.fcmInstance = fcm;
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("New content is available; please refresh.");
      if (!registration || !registration.waiting) {
        return;
      }
      document.dispatchEvent(
        new CustomEvent("swCheckUpdate", { detail: registration })
      );

      /*document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: registration })
      );*/
      //Force to load new version of service worker
      //registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
