import moment from "moment";
const sortOrderDays = (order) => {
  const orderDays = order.days;
  let days = [];

  for (let index in orderDays) {
    let day = days.find((el) => el.day == orderDays[index].day);
    let timeSlotIndex = Number.parseInt(orderDays[index].timeSlot);

    if (!day) {
      day = {
        day: orderDays[index].day,
        meals: [],
      };
      days.push(day);
    }

    let mealItem = day.meals.find(
      (el) => el.meal.gerichtnr == [orderDays[index].meal.gerichtnr]
    );

    if (!mealItem) {
      mealItem = {
        meal: orderDays[index].meal,
        timeSlots: [],
        totalAmount: 0,
      };
      day.meals.push(mealItem);
    }

    if (orderDays[index].amount > 0) {
      mealItem.timeSlots[timeSlotIndex] = orderDays[index].amount;
      mealItem.totalAmount += orderDays[index].amount;
    }
  }
  const sorted = days.sort((a, b) => {
    return (
      moment(a.day, "DD.MM.YYYY").format("YYYYMMDD") -
      moment(b.day, "DD.MM.YYYY").format("YYYYMMDD")
    );
  });
  return sorted;
};

export default {
  sortOrderDays,
};
