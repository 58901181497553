import DataService from "@/services/DataService.js";

export default {
  login(data) {
    return DataService.apiClient.post("/user/login", data);
  },
  refreshToken(refresh_token) {
    return DataService.apiClient.post("/user/token", {
      refreshToken: refresh_token,
    });
  },
  logout() {
    return DataService.apiClient.delete("/user/logout");
  },
  setAuthToken(token) {
    DataService.apiClient.defaults.headers.common[
      "Authorization"
    ] = ` Bearer ${token}`;
  },
  fetchAll() {
    return DataService.apiClient.get("/user");
  },

  fetch(id) {
    return DataService.apiClient.get("/user/" + id);
  },
  saveUserPassword(userid, password) {
    return DataService.apiClient.patch("/user/changePassword/" + userid, {
      password: password,
    });
  },
  saveUser(user) {
    if (user.id != null) {
      return DataService.apiClient.patch("/user/edit/" + user.id, user.data);
    } else {
      return DataService.apiClient.post("/user/add", user.data);
    }
  },

  //Push calls
  subscriptionCheck(token) {
    return DataService.apiClient.get(`/notification/check/${token}`);
  },
  subscribePush(data) {
    return DataService.apiClient.post("/notification/subscribe", data);
  },
  unsubscribePush(token) {
    if (!token || token == undefined) {
      return Promise.reject(new Error("No token set"));
    }
    return DataService.apiClient.delete(`/notification/unsubscribe/${token}`);
  },
};
