import MealService from "@/services/MealService.js";
import OrderService from "@/services/OrderService.js";

const state = {
  showMealPopup: false,
  contentView: 0,
  mealsearch: "",
  kategorien: ["Vorspeisen", "Hauptspeisen", "Desserts"],
  subKategorien: [],
  filterKategorien: ["Vorspeisen", "Hauptspeisen", "Desserts"],
  filterSubKategorien: [],
  filterAllergene: [],
  showExtendedFilter: false,
  mealList: [],
  showOnlyGrossgebindeMeals: false,
  resteMealList: [],
  meal: null,
  allergene: [
    {
      text: "Getreide (A)",
      value: "A",
    },
    {
      text: "Krebstiere (B)",
      value: "B",
    },
    {
      text: "Eier (C)",
      value: "C",
    },
    {
      text: "Fisch (D)",
      value: "D",
    },
    {
      text: "Erdnüsse (E)",
      value: "E",
    },
    {
      text: "Sojabohnen (F)",
      value: "F",
    },
    {
      text: "Milch (G)",
      value: "G",
    },
    {
      text: "Schalenfrüchte (H)",
      value: "H",
    },
    {
      text: "Sellerie (L)",
      value: "L",
    },
    {
      text: "Senf (M)",
      value: "M",
    },
    {
      text: "Sesam (N)",
      value: "N",
    },
    {
      text: "Schwefeldioxid und Sulfate (O)",
      value: "O",
    },
    {
      text: "Lupinen (P)",
      value: "P",
    },
    {
      text: "Weichtiere (R)",
      value: "R",
    },
  ],
};

const getters = {
  isMealPopupShown(state) {
    return state.showMealPopup;
  },
  isExtendedFilterShown(state) {
    return state.showExtendedFilter;
  },
  getMealList(state) {
    return state.mealList;
  },
  getResteMealList(state) {
    return state.resteMealList;
  },
  getCurrent(state) {
    return state.meal;
  },
  getAllergene(state) {
    return state.allergene;
  },
  getKategorien(state) {
    return state.kategorien;
  },
  getSubKategorien(state) {
    return state.subKategorien;
  },

  getFilterKategorien(state) {
    return state.filterKategorien;
  },
  getFilterSubKategorien(state) {
    return state.filterSubKategorien;
  },
  getFilterAllergene(state) {
    return state.filterAllergene;
  },
  getMealSearch(state) {
    return state.mealsearch;
  },
  getContentView(state) {
    return state.contentView;
  },
  isFilterIcon(state) {
    return state.showFilterIcon;
  },
  isShowOnlyGrossgebindeMeals(state) {
    return state.showOnlyGrossgebindeMeals;
  },
};

const actions = {
  findMeals(name) {
    console.log("Search: " + name);
    //return new Promise((resolve, reject) => {});
  },
  fetchMealList({ commit }, dates) {
    return new Promise((resolve, reject) => {
      //MealService.getByDate(date)

      //MealService.getAll()
      MealService.getByDateRange(dates.startdate, dates.enddate)
        .then((response) => {
          commit("SET_MEAL_LIST", response.data);
          resolve(response);
        })
        .catch((error) => {
          //console.log(error.response.data.error);
          reject(error);
        });
    });
  },
  fetchMeal({ commit }, id) {
    return new Promise((resolve, reject) => {
      MealService.get(id)
        .then((response) => {
          commit("SET_MEAL", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchUnpickedMeals({ commit }, lieferort = "all") {
    return new Promise((resolve, reject) => {
      OrderService.fetchUnpickedMeals(lieferort)
        .then((response) => {
          commit("SET_RESTE_MEALS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SHOW_EXTENDED_FILTER(state, value) {
    state.showExtendedFilter = value;
  },

  SET_MEAL_SEARCH(state, value) {
    state.mealsearch = value;
  },
  SET_CONTENT_VIEW(state, value) {
    state.contentView = value;
  },

  SET_FILTER_KATEGORIEN(state, value) {
    state.filterKategorien = value;
  },

  SET_FILTER_SUBKATEGORIEN(state, value) {
    state.filterSubKategorien = value;
  },

  SET_FILTER_ALLERGENE(state, value) {
    state.filterAllergene = value;
  },

  SET_MEAL_LIST(state, value) {
    state.mealList = value.data;
    state.showOnlyGrossgebindeMeals = value.showOnlyGrossgebindeMeals;

    //Finde subKategorien
    let filter = [];
    for (let meal of value.data) {
      let foundMain = filter.find((item) => item.kategorie === meal.kategorie);

      if (!foundMain) {
        foundMain = {
          kategorie: meal.kategorie,
          visible: true,
          subKategorien: [
            {
              name: "Alle",
              active: true,
            },
          ],
        };

        filter.push(foundMain);
      }

      if (!meal.subkategorie) {
        continue;
      }

      let foundSub = filter.find(
        (item) =>
          item.kategorie === meal.kategorie &&
          item.subKategorien.find((sub) => sub.name === meal.subkategorie)
      );
      if (foundSub) {
        continue;
      }

      foundMain.subKategorien.push({
        name: meal.subkategorie,
        active: false,
      });
    }

    //state.subKategorien = filter;
    state.filterSubKategorien = filter;
  },
  SET_RESTE_MEALS(state, value) {
    let meallist = [];
    if (Array.isArray(value) && value.length > 0) {
      for (let item of value) {
        let meal = { ...item._id.meal };
        meal.count = item.count;
        meallist.push(meal);
      }
    }

    state.resteMealList = meallist;
  },
  SET_MEAL(state, value) {
    state.meal = value;
  },
  TOGGLE_MEAL_POPUP(state, value) {
    state.showMealPopup = !!value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
