import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Notifications from "vue-notification";
import vuetify from "./plugins/vuetify";
import DataService from "./services/DataService";
import { version } from "../package.json";
Vue.use(Notifications);

Vue.config.productionTip = false;

DataService.apiClient.interceptors.request.use(
  (config) => {
    // add app version to headers
    config.headers["APP-VERSION"] = version;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
DataService.apiClient.interceptors.response.use(
  //Antwort  im Erfolgsfall zurück geben
  (response) => {
    return response;
  },
  //Fehler behandeln
  (error) => {
    //console.log("Fehler",error);
    if (error.response && error.response.status !== 401) {
      if (error.response.data.error) {
        error.message = error.response.data.error;
      }
      return Promise.reject(error);
    } else if (error.response && error.response.status === 401) {
      const origRequest = error.config;
      //console.log("ERROR", error.response);
      // if (error.response.data.error == "Token expired") {
      //disable alerts on refreshing
      //}

      if (!origRequest._retry) {
        origRequest._retry = true;
        store
          .dispatch("user/refreshToken")
          .then((result) => {
            if (result.status !== 200) {
              //logout
              store.dispatch("user/clearStoredUser").then(() => {
                router.push({ name: "login" });
                //return Promise.reject(error);
                return Promise.resolve(result);
              });
            } else {
              //resend original request with new token
              return DataService.apiClient(origRequest);
            }
          })
          .catch((error) => {
            store.dispatch("user/clearStoredUser").finally(() => {
              router.push({ name: "login" });
              return Promise.reject(error);
            });
          });
      }
      //Fehler zurück geben da sonst die Fehlerhandling Kette unterbrochen wird
      return Promise.reject(error);
    } else if (error.response && error.response.status === 403) {
      // Logout user if token refresh didn't work or user is disabled
      store.dispatch("user/logout").finally(() => {
        router.push({ name: "login" });
        return Promise.reject(error);
      });

      // Ende Logout
    }
  }
);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
