import DataService from "@/services/DataService.js";

export default {
  fetch(userid, orderid) {
    return DataService.apiClient.get("/order/" + userid + "/" + orderid);
  },
  fetchDates() {
    return DataService.apiClient.get("/order/dates");
  },
  checkQRScan(artnr, orderid) {
    return DataService.apiClient.post("/order/checkqrscan", {
      artnr,
      orderid,
    });
  },
  fetchCurrent(userid) {
    return DataService.apiClient.get("/order/current/" + userid);
  },
  fetchActual(userid) {
    return DataService.apiClient.get("/order/actual/" + userid);
  },
  fetchUserLast(userid, ordereMode = "single") {
    return DataService.apiClient.get(
      `/order/last/${userid}/?mode=${ordereMode}`
    );
  },
  fetchAll(userid) {
    return DataService.apiClient.get("/order/byUser/" + userid);
  },
  fetchUnpickedMeals(lieferort) {
    return DataService.apiClient.get(`/order/unpicked/${lieferort}`);
  },
  getNextUnpickedOrder(mealId, gerichtnr, amount) {
    return DataService.apiClient.post(`/order/getNextUnpickedOrder`, {
      mealId,
      gerichtnr,
      amount,
    });
  },
  updateUnpickedMeal(mealId, gerichtnr, amount) {
    return DataService.apiClient.patch(`/order/updateUnpicked`, {
      mealId,
      gerichtnr,
      amount,
    });
  },

  saveOrder(order) {
    if (order._id != null) {
      return DataService.apiClient.patch("/order/" + order._id, order);
    } else {
      return DataService.apiClient.post("/order/", order);
    }
  },
};
