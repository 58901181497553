<template>
  <v-flex>
    <v-container fluid class="pa-0 ma-0">
      <v-row class="pa-2">
        <v-col>
          <v-text-field
            v-model="search"
            dark
            color="white"
            label="Gericht suchen"
            dense
            single-line
            outlined
            hide-details
            clearable
            append-outer-icon="mdi-filter"
            @click:append-outer="toggleFilter"
          >
            <v-icon color="white">mdi-filter</v-icon>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-flex>
</template>

<script>
export default {
  name: "SearchFilter",
  methods: {
    toggleFilter() {
      this.extendedFilterShown = !this.extendedFilterShown;
    },
  },
  computed: {
    search: {
      get() {
        return this.$store.getters["meal/getMealSearch"];
      },
      set(meal) {
        this.$store.commit("meal/SET_MEAL_SEARCH", meal);
      },
    },

    extendedFilterShown: {
      get() {
        return this.$store.getters["meal/isExtendedFilterShown"];
      },
      set(show) {
        this.$store.commit("meal/SHOW_EXTENDED_FILTER", show);
      },
    },
  },
};
</script>

<style></style>
