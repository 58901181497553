<template>
  <v-app id="main" class="pa-0">
    <v-container class="background"></v-container>
    <v-container class="loader" v-show="isLoading">
      <v-layout align-center justify-center column fill-height>
        <v-flex row align-center>
          <v-progress-circular
            indeterminate
            :size="50"
            color="accent"
            class=""
          ></v-progress-circular>
        </v-flex>
      </v-layout>
    </v-container>

    <Header v-if="loggedIn" />

    <v-main style="padding-top: 0px" class="fill-height">
      <v-snackbar
        v-model="showInfoSnack"
        bottom
        left
        timeout="10000"
        tile
        color="info"
      >
        <v-icon class="mr-2">mdi-information-outline</v-icon>
        {{ infoSnackText }}
        <template v-slot:action="{}">
          <v-btn icon class="ml-4" @click="showInfoSnack = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="connectivityStatus"
        bottom
        left
        timeout="-1"
        color="error"
      >
        {{ connectivityText }}
        <template v-slot:action="{}">
          <v-btn
            icon
            class="ml-4"
            color="#00f500"
            @click="connectivityStatus = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="infoSnackbar.show"
        top
        centered
        :timeout="infoSnackbar.timeout || 3000"
        :color="infoSnackbar.bgColor || 'info'"
        multi-line
      >
        <h3>{{ infoSnackbar.title }}</h3>

        <span>{{ infoSnackbar.body }}</span>
        <template v-slot:action="{}">
          <v-btn
            icon
            class="ml-4"
            color="primary"
            @click="infoSnackbar.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-fade-transition>
        <router-view></router-view>
      </v-fade-transition>
      <notifications group="dataline" position="top right" />
    </v-main>
  </v-app>
</template>

<script>
import DataService from "@/services/DataService";
import axios from "axios";
import Header from "@/components/Header";

export default {
  name: "MainApp",
  components: {
    Header,
  },
  data: () => ({
    filterVorspeisen: true,
    filterHauptgerichte: true,
    filterDesserts: true,
    snackBtnText: "",
    infoSnackText: "",
    showInfoSnack: false,
    refreshing: false,
    registration: null,
    updateExists: false,
    connectivityStatus: false,
    connectivityText: "",
    appRefreshed: false,
  }),
  beforeCreate() {
    this.$store
      .dispatch("user/loadUserFromStore")
      .then(() => {
        //if user and site is login forward to home
        // console.log(this.$router.currentRoute.name);
        if (
          this.$router.currentRoute.name == "login" ||
          !this.$router.currentRoute.name
        ) {
          this.$router.push({ name: "home" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "login" });
      });

    DataService.apiClient.interceptors.request.use(
      (config) => {
        var user = JSON.parse(localStorage.getItem("order-user"));
        //console.log("REQ", config);
        if (user == null && config.url != "/user/login") {
          throw new axios.Cancel("not logged in");
        }
        if (user != null) {
          config.headers.Authorization = `Bearer ${user.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  created() {
    //check if appRefreshed is in localStorage
    this.appRefreshed = localStorage.getItem("appRefreshed") || false;
    //Load viewPane from localStorage if present from update
    this.viewPane = Number.parseInt(localStorage.getItem("viewpane")) || 0;

    if (this.appRefreshed) {
      //show update message in snackbar
      this.showUpdatedMessage();
      //reset appRefreshed state
      this.appRefreshed = false;
      //remove appRefreshed from localstorage
      localStorage.removeItem("appRefreshed");
      localStorage.removeItem("viewpane");

      //fire appRefreshed event to load the data from the database
      document.dispatchEvent(new CustomEvent("appRefreshed"));
    }

    //document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    document.addEventListener("swCheckUpdate", this.checkUpdate, {
      once: true,
    });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }

    window.addEventListener("offline", () => {
      //console.log("offline");
      this.connectivityStatus = true;
      this.connectivityText =
        "Du scheinst Offline zu sein. Bitte prüfe deine Internetverbindung.";
    });
    window.addEventListener("online", () => {
      //console.log("online");
      this.connectivityStatus = false;
    });

    //Handle Browser Back button
    window.addEventListener("popstate", (e) => {
      // Nope, go back to your page

      e.stopImmediatePropagation();
      e.preventDefault();

      //Hide daySelector Dialog
      if (this.showDaySelector) {
        this.showDaySelector = false;
        return;
      }

      //Hide Meal Details Dialog
      if (this.showMealPopup) {
        this.showMealPopup = false;
        return;
      }

      //Hide Order Details Dialog
      if (this.showOrderDetails) {
        this.showOrderDetails = false;
        return;
      }

      //Hide OrderOverview Details Dialog
      if (this.showOrderOverviewDetails) {
        this.showOrderOverviewDetails = false;
        return;
      }

      //Hide Lieferort Selector
      if (this.showLieferorteSelector) {
        this.showLieferorteSelector = false;
        return;
      }

      //Hide Order Pickup Result
      if (this.showOrderPickupResult) {
        this.showOrderPickupResult = false;
      }

      if (this.viewPane == 1 && this.orderPane == 1) {
        this.orderPane = 0;
      } else {
        this.viewPane = 0;
        history.go(1);
      }
    });
  },

  mounted() {
    //create new history object
    history.pushState(null, null, null);
  },
  beforeDestroy() {},

  methods: {
    backbuttonClicked(evt) {
      console.log(evt);
      this.$store.commit("SET_VIEW_PANE", 0);
    },
    checkUpdate(e) {
      //console.log("check for update");
      //console.log("ViewPane: ", this.viewPane);

      if (e) {
        localStorage.setItem("viewpane", this.viewPane);
        localStorage.setItem("appRefreshed", true);
        e.detail.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
    showUpdatedMessage() {
      this.infoSnackText = "Die App wurde upgedated";
      this.showInfoSnack = true;
    },
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;

      this.snackBtnText = "Update";
      this.infoSnackText = "Die App wurde upgedated";
      this.showInfoSnack = false;
    },
    refreshApp() {
      this.showInfoSnack = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters["user/loggedIn"];
    },
    isLoading() {
      return this.$store.getters["appLoading"];
    },

    pushNotification: {
      get() {
        return this.$store.getters["getNotification"];
      },
      set(value) {
        this.$store.commit("SHOW_NOTIFICATION", { show: value });
      },
    },

    infoSnackbar: {
      get() {
        return this.$store.getters["getInfoSnackbar"];
      },
      set(value) {
        this.$store.commit("SET_INFO_SNACKBAR", value);
      },
    },

    filterKategorien: {
      get() {
        //console.log(this.$store.getters["meal/getFilterKategorien"]);
        return this.$store.getters["meal/getFilterKategorien"];
      },
      set(value) {
        this.$store.commit("meal/SET_FILTER_KATEGORIEN", value);
      },
    },

    showDaySelector: {
      get() {
        return this.$store.getters["order/isDaySelectorShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_DAY_SELECTOR", value);
      },
    },

    showMealPopup: {
      get() {
        return this.$store.getters["meal/isMealPopupShown"];
      },
      set(value) {
        this.$store.commit("meal/TOGGLE_MEAL_POPUP", value);
      },
    },

    showOrderDetails: {
      get() {
        return this.$store.getters["order/isOrderDetailsShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_ORDER_DETAILS", value);
      },
    },

    showOrderOverviewDetails: {
      get() {
        return this.$store.getters["order/isOrderOverviewDetailsShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_ORDER_OVERVIEW_DETAILS", value);
      },
    },

    showLieferorteSelector: {
      get() {
        return this.$store.getters["order/isLieferortSelectorShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_LIEFERORTE_SELECTOR", value);
      },
    },

    showOrderPickupResult: {
      get() {
        return this.$store.getters["order/isOrderPickupResultDialogShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_ORDER_PICKUP_RESULT", value);
      },
    },

    orderPane: {
      get() {
        return this.$store.getters["getOrderPane"];
      },
      set(pane) {
        this.$store.commit("SET_ORDER_PANE", pane);
      },
    },

    viewPane: {
      get() {
        return this.$store.getters["getViewPane"];
      },
      set(pane) {
        this.$store.commit("SET_VIEW_PANE", pane);
      },
    },
  },
};
</script>

<style>
@import "https://api.anewo.at/ressources/css/custom.css";
html {
  overscroll-behavior-y: contain;
}
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.background {
  background-image: url(./assets/bg2.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  filter: blur(10px) !important;
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: fixed;
}
.background2 {
  background-image: url(./assets/makeorder.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  filter: blur(10px) !important;
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: fixed;
}

#main {
  padding: 0;
}
#appbar .v-toolbar__content,
#appbar .v-toolbar__extension {
  height: 100% !important;
  padding: 4px 0px !important;
}
.maincard {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

#content {
  background: rgba(255, 255, 255, 0.4);
}

.copyright {
  font-size: 12px;
}

.loader {
  background-color: rgba(0, 0, 0, 0.8);
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100% !important;
  position: fixed;
  z-index: 9999;
}
h1,
h2,
h3 {
  padding: 20px 0px;
  margin-bottom: 10px;
}

.categoryVorspeisen,
.categoryHauptspeisen,
.categoryDesserts {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 21; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.categoryVorspeisen.on,
.categoryHauptspeisen.on,
.categoryDesserts.on {
  display: block;
  -webkit-animation: fadeout 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 0.5s; /* Firefox < 16 */
  -ms-animation: fadeout 0.5s; /* Internet Explorer */
  -o-animation: fadeout 0.5s; /* Opera < 12.1 */
  animation: fadeout 0.5s;
  animation-fill-mode: forwards;
}

.fadein {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 10.5ss;
}

.fadeout {
  display: block;
  -webkit-animation: fadeout 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 20.5s1; /* Firefox < 16 */
  -ms-animation: fadeout 0.5s; /* Internet Explorer */
  -o-animation: fadeout 0.5s; /* Opera < 12.1 */
  animation: fadeout 0.5s;
  animation-fill-mode: forwards;
}

.fadeout-hidden {
  display: block;
  -webkit-animation: fadeout 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 0.5s; /* Firefox < 16 */
  -ms-animation: fadeout 0.5s; /* Internet Explorer */
  -o-animation: fadeout 0.5s; /* Opera < 12.1 */
  animation: fadeout 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
