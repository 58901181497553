<template>
  <v-flex>
    <v-card tile flat class="daySelector" color="accent" align="center">
      <v-icon class="mr-4" @click="decrementSelectedDay"
        >mdi-chevron-left</v-icon
      >
      <v-icon class="mr-0" color="white" @click="showDaySelector = true"
        >mdi-calendar</v-icon
      >

      <span @click="showDaySelector = true" class="selectedDateLabel"
        >{{ selectedDayName }} - {{ selectedDate }}</span
      >
      <v-icon class="ml-4" @click="incrementSelectedDay"
        >mdi-chevron-right</v-icon
      >
    </v-card>

    <v-dialog :value="showDaySelector" fullscreen overlay-color="white">
      <v-container fluid class="background2"></v-container>
      <v-container fluid class="fill-height pa-0">
        <v-toolbar dark>
          <v-toolbar-title>Bestelltag auswählen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDaySelector = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-row>
          <v-col>
            <v-card tile>
              <v-card-text> Bestellende {{ bestellEnde }} </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="fill-height">
          <v-col cols="12" xs="12">
            <v-card
              v-for="(item, index) in orderDays"
              :key="index"
              class="daycard ma-3 pa-4"
              align="center"
              @click="selectDay(item)"
            >
              <v-layout>
                <v-layout column></v-layout>

                <v-layout column justify-center>
                  <div class="dayName">{{ item.format("dddd") }}</div>
                  <div class="dayDate">{{ item.format("DD.MM.YYYY") }}</div>
                </v-layout>
                <v-layout column justify-center align-end>
                  <v-icon v-show="hasDayOrders(item)" color="green"
                    >mdi-check-circle</v-icon
                  >
                </v-layout>
              </v-layout>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </v-flex>
</template>

<script>
import moment from "moment";

export default {
  name: "DaySelector",
  props: {
    openOnLoad: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),

  methods: {
    incrementSelectedDay() {
      let orderDayIndex = this.orderDays.findIndex(
        (el) => el === this.selectedDay
      );

      if (orderDayIndex < this.orderDays.length - 1) {
        orderDayIndex++;
      } else if (orderDayIndex >= this.orderDays.length - 1) {
        orderDayIndex = 0;
      }

      this.selectedDay = this.orderDays[orderDayIndex];
      this.$emit("change", this.selectedDay);
    },
    decrementSelectedDay() {
      let orderDayIndex = this.orderDays.findIndex(
        (el) => el === this.selectedDay
      );

      if (orderDayIndex > 0) {
        orderDayIndex--;
      } else if (orderDayIndex <= 0) {
        orderDayIndex = this.orderDays.length - 1;
      }

      this.selectedDay = this.orderDays[orderDayIndex];
      this.$emit("change", this.selectedDay);
    },
    selectDay(date) {
      this.selectedDay = date;
      this.showDaySelector = false;
      this.$emit("change", date);
    },
    getDayName(date) {
      return date.format("dddd");
    },
    getDate(date) {
      return date.format("DD.MM.YYYY");
    },
    hasDayOrders(date) {
      const dayOrders = this.$store.getters["order/getOrder"].days;
      if (dayOrders instanceof Array) {
        const meal = dayOrders.find(
          (item) => item.day == date.format("DD.MM.YYYY") && item.amount > 0
        );

        if (meal) {
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    showDaySelector: {
      get() {
        return this.$store.getters["order/isDaySelectorShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_DAY_SELECTOR", value);
      },
    },
    selectedDay: {
      get() {
        let day = this.$store.getters["order/getSelectedDay"];

        if (!day) {
          let zeitraum = this.$store.getters["order/getOrderZeitraum"];
          if (zeitraum) {
            day = zeitraum[0];
          }
        }
        return day;
      },
      set(date) {
        this.$store.commit("order/SET_SELECTED_DAY", date);
      },
    },
    selectedDayName() {
      let selectedDay = this.selectedDay;
      if (selectedDay) {
        return moment(selectedDay).format("dddd");
      }
      return "";
    },
    selectedDate() {
      let selectedDay = this.selectedDay;
      if (selectedDay) {
        return moment(selectedDay).format("DD.MM.YYYY");
      }
      return "";
    },
    orderDays() {
      return this.$store.getters["order/getOrderDays"];
    },
    isBestellEnde() {
      return this.$store.getters["order/isBestellEnde"];
    },
    bestellEnde() {
      let ende = this.$store.getters["order/getBestellEnde"];
      if (!ende) {
        ende = moment();
      }
      return ende.format("DD.MM.YYYY HH:mm") + " Uhr";
    },

    Customer: {
      get() {
        return this.$store.getters["customer/getCustomer"];
      },
      set(value) {
        this.$store.commit("customer/SET_CUSTOMER", value);
      },
    },
  },
  created() {
    moment.locale("de-de");
    if (this.openOnLoad) {
      this.showDaySelector = true;
    }
  },

  mounted() {},
};
</script>

<style scoped>
.selectedDateLabel {
  max-width: 200px !important;
  width: 200px !important;
  min-width: 200px !important;
  display: inline-block;
}
.daySelector {
  background-color: #c25443 !important;
  color: #fff;
  padding: 10px 4px 10px 4px;
  font-size: 16px;
}
.daycard {
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: #fff !important;
  padding: 4px;
  opacity: 1;
}

.dayName {
  font-size: 26px;
  font-weight: bold;
}

.dayDate {
  font-size: 20px;
}
</style>
