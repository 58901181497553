import DataService from "@/services/DataService.js";

export default {
  fetchCustomer(id) {
    return DataService.apiClient.get("/customer/" + id);
  },
  fetchBranding() {
    return DataService.apiClient.get(`/customer/branding/find`);
  },
};
